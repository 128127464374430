import { OrganizationPeriod, PeriodStatus } from '@/types/graphql-types';
import { create } from 'zustand';
import { isPeriodReviewed } from './useGetPeriods';

export enum ClosingPageError {
  PERIOD_NOT_FOUND = 'PERIOD_NOT_FOUND',
}

// The numeric value is important here as it is used to determine the order of the steps
export enum ClosingStepType {
  Review = 1,
  Sync = 2,
  Lock = 3,
  Close = 4,
  Completed = 5,
}
export const ALL_CLOSING_STEPS = Object.values(ClosingStepType).filter(
  (value): value is ClosingStepType => typeof value === 'number',
);

export enum ClosingErrors {
  JournalEntriesSyncFailed = 'JournalEntriesSyncFailed',
  PeriodLockFailed = 'PeriodLockFailed',
  PeriodCloseFailed = 'PeriodCloseFailed',
}

export enum ClosingWarnings {
  JournalEntriesUnBalanced = 'JournalEntriesUnBalanced',
  JournalEntriesMissingAccounts = 'JournalEntriesMissingAccounts',
  BalanceDifferences = 'BalanceDifferences',
}

export enum ClosingStepStatus {
  NotStarted,
  Running,
  Completed,
  Error,
}

export type ClosingStepState = {
  step: ClosingStepType;
  status: ClosingStepStatus;
  error?: ClosingErrors | null;
  skipped?: boolean;
};

const resetSteps = () => {
  return new Map(
    ALL_CLOSING_STEPS.map((step) => [step, initialStepState(step)]),
  );
};

const initialStepState = (step: ClosingStepType) => {
  return {
    step,
    status: ClosingStepStatus.NotStarted,
    error: null,
    skipped: false,
  };
};

interface ClosingPageStore {
  isLoadingPage: boolean;
  setIsLoadingPage: (loading: boolean) => void;
  pageError: ClosingPageError | null;
  setPageError: (error: ClosingPageError) => void;

  syncedJEs: boolean;
  setSyncedJEs: (synced: boolean) => void;

  currentPeriod: OrganizationPeriod | null;
  setCurrentPeriod: (period: OrganizationPeriod) => void;

  getCurrentStep: () => ClosingStepType | null;
  steps: Map<ClosingStepType, ClosingStepState>;
  setSteps: (steps: Map<ClosingStepType, ClosingStepState>) => void;
  updateStepState: (
    step: ClosingStepType,
    update: Partial<ClosingStepState>,
  ) => void;
  skipStep: (step: ClosingStepType) => void;

  closingErrors: Set<ClosingErrors>;
  setClosingErrors: (errors: Set<ClosingErrors>) => void;

  closingWarnings: Set<ClosingWarnings>;
  setClosingWarnings: (warnings: Set<ClosingWarnings>) => void;
}

export const useClosingPageStore = create<ClosingPageStore>((set, get) => ({
  isLoadingPage: true,
  setIsLoadingPage: (loading) => set({ isLoadingPage: loading }),
  pageError: null,
  setPageError: (error) => set({ pageError: error }),

  syncedJEs: false,
  setSyncedJEs: (synced) => set({ syncedJEs: synced }),

  currentPeriod: null,
  setCurrentPeriod: (period) =>
    // Reset the steps to initial state when the current period changes, so the useSetClosingProgress hook can determine the correct state
    set({ currentPeriod: period, steps: resetSteps() }),

  getCurrentStep: () => {
    const { currentPeriod, steps } = get();

    if (!currentPeriod) return null;
    const syncStep = steps.get(ClosingStepType.Sync);
    const isReviewed = isPeriodReviewed(currentPeriod);

    switch (currentPeriod.status) {
      case PeriodStatus.Open:
        if (!isReviewed) {
          return ClosingStepType.Review;
        }

        if (
          syncStep?.skipped ||
          syncStep?.status === ClosingStepStatus.Completed
        ) {
          return ClosingStepType.Lock;
        }
        return ClosingStepType.Sync;
      case PeriodStatus.Closing:
        return ClosingStepType.Close;
      case PeriodStatus.Closed:
        return ClosingStepType.Completed;
      default:
        return null;
    }
  },

  steps: resetSteps(),
  setSteps: (steps) => set({ steps: steps }),
  updateStepState: (step, update) =>
    set((state) => ({
      steps: new Map(state.steps).set(step, {
        ...state.steps.get(step),
        ...update,
      }),
    })),
  skipStep: (step) =>
    set((state) => {
      const currentStepState = state.steps.get(step);
      if (currentStepState.status === ClosingStepStatus.Running) {
        return state;
      }
      return {
        steps: new Map(state.steps).set(step, {
          ...currentStepState,
          skipped: true,
        }),
      };
    }),

  closingErrors: new Set<ClosingErrors>(),
  setClosingErrors: (errors) => set(() => ({ closingErrors: errors })),

  closingWarnings: new Set<ClosingWarnings>(),
  setClosingWarnings: (warnings) => set(() => ({ closingWarnings: warnings })),
}));

export const areStepsEqual = (
  stepsA: Map<ClosingStepType, ClosingStepState>,
  stepsB: Map<ClosingStepType, ClosingStepState>,
) => {
  if (stepsA.size !== stepsB.size) return false;
  for (const [key, valueA] of stepsA) {
    const valueB = stepsB.get(key);
    if (!valueB || !isEqual(valueA, valueB)) return false;
  }
  return true;
};

export const areSetsEqual = <T>(setA: Set<T>, setB: Set<T>): boolean => {
  if (setA.size !== setB.size) return false;
  for (const item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
};

export const isEqual = (a: unknown, b: unknown): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};
