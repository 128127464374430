import {
  ClosingStepState,
  ClosingStepStatus,
  ClosingStepType,
  useClosingPageStore,
} from '@/pages/closing/hooks/ClosingPageStore';
import { Card, Flex, Heading } from '@radix-ui/themes';
import { JobStatusIcon } from './JobStatusIcon';

const getJobStatusText = (currentStep: ClosingStepState) => {
  switch (currentStep.status) {
    case ClosingStepStatus.Running:
      switch (currentStep.step) {
        case ClosingStepType.Sync:
          return 'Syncing journal entries';
        case ClosingStepType.Lock:
          return 'Locking period';
        case ClosingStepType.Close:
          return 'Closing period';
      }
      break;
    case ClosingStepStatus.Error:
      switch (currentStep.step) {
        case ClosingStepType.Sync:
          return 'Syncing journal entries failed';
        case ClosingStepType.Lock:
          return 'Locking period failed';
        case ClosingStepType.Close:
          return 'Closing period failed';
      }
      break;
  }
  return 'Closing process';
};

export const ClosingProcessJobStatus = () => {
  const { getCurrentStep, steps } = useClosingPageStore();
  const currentStep = steps.get(getCurrentStep());

  const isIdle =
    currentStep?.status !== ClosingStepStatus.Running &&
    currentStep?.status !== ClosingStepStatus.Error;
  return (
    <Card variant="ghost">
      <Flex direction="column" gap="2" width="400px">
        <Flex direction="row" justify="between" align="center">
          <Flex direction="row" gap="2" align="center">
            <JobStatusIcon
              isRunning={currentStep?.status === ClosingStepStatus.Running}
              hasError={currentStep?.status === ClosingStepStatus.Error}
              isIdle={isIdle}
            />
            <Heading size="3">{getJobStatusText(currentStep)}</Heading>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
