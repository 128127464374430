import * as RadixAvatar from '@radix-ui/react-avatar';
import { isChromaticEnv } from 'src/app/utils';
import styled from 'styled-components';

interface AvatarProps {
  src: string;
  alt: string;
  fallbackText?: string;
  fallbackBackgroundColor?: string;
  fallbackColor?: string;
  size?: number;
  delayMs?: number;
  className?: string;
}
export default function Avatar({
  src,
  alt,
  fallbackText = '',
  fallbackBackgroundColor = '',
  fallbackColor = '',
  size = 24,
  delayMs = 0,
  className,
}: AvatarProps) {
  // we are forcing avatars to load up their fallbackTexts instead of waiting because they create flaky tests
  if (isChromaticEnv()) {
    return (
      <AvatarForVisualTests $size={size} className={className}>
        {fallbackText}
      </AvatarForVisualTests>
    );
  }
  return (
    <AvatarRoot $size={size} className={className}>
      <AvatarImage src={src} alt={alt} />
      {fallbackText && (
        <AvatarFallback
          delayMs={delayMs}
          $backgroundColor={fallbackBackgroundColor}
          $color={fallbackColor}
        >
          {fallbackText}
        </AvatarFallback>
      )}
    </AvatarRoot>
  );
}

export const AvatarRoot = styled(RadixAvatar.Root)<{ $size?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  flex-shrink: 0;
  margin-right: 8px;
`;
const AvatarImage = styled(RadixAvatar.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-radius: 50%;
`;
const AvatarFallback = styled(RadixAvatar.Fallback)<{
  $backgroundColor?: string;
  $color?: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;

  ${({ $color }) => $color && `color: ${$color};`};

  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.grey.mid};
`;

export const AvatarForVisualTests = styled.div<{ $size?: number }>`
  width: ${({ $size = 16 }) => $size}px;
  height: ${({ $size = 16 }) => $size}px;
  border-radius: 50%;
  background: ${({ theme }) => theme.grey.mid};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 75%;
  overflow: hidden;
  margin-right: 8px;
`;
