import { useFileUploader } from '@/common/file-uploader-hook';
import { DuplicateTransactionsCallout } from '@/components/DuplicateTransactionsCallout';
import {
  GetValidationErrorsForCsvQuery,
  GetValidationErrorsForCsvQueryVariables,
  Integration,
} from '@/types/graphql-types';
import { LazyQueryHookOptions, LazyQueryResult } from '@apollo/client';
import { Button, Callout, Flex, Spinner, Text } from '@radix-ui/themes';
import { Info } from 'lucide-react';
import { useState } from 'react';
import { FormContent, TextFormField } from '../../FormBaseComponents';
import { Instructions } from '../../Instructions';
import { CoverageInfoContainer, InstructionContainer } from '../styles';
import { FileInput } from './FileInput';
import { GenericInstructions } from './GenericInstructions';

export interface CSVFormProps {
  integration: Integration;
  onCloseModal?: () => void;
  formAction?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  fileUrl?: string;
  isSubmitting?: boolean;
  onFileChange?: (filepath?: string) => void;
  importError?: string;
  getValidationErrorsForCsv?: (
    options?: LazyQueryHookOptions<
      GetValidationErrorsForCsvQuery,
      { csvFileUrl?: string }
    >,
  ) => Promise<
    LazyQueryResult<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >
  >;
  isGeneric?: boolean;
  needsDuplicatesCallout?: boolean;
}

const getButtonText = (
  isSubmitting: boolean,
  isUploading: boolean,
  isValidating: boolean,
) => {
  let buttonText = '';
  if (isSubmitting) {
    buttonText = 'Importing';
  }
  if (isUploading) {
    buttonText = 'Uploading';
  }
  if (isValidating) {
    buttonText = 'Validating';
  }

  if (buttonText) {
    return (
      <Flex direction="row" gap="4">
        <Text>{buttonText}</Text>
        <Spinner />
      </Flex>
    );
  }
  return 'Import';
};

export const CSVForm = (props: CSVFormProps) => {
  const {
    integration,
    onCloseModal,
    formAction,
    fileUrl,
    isSubmitting,
    onFileChange,
    onSubmit,
    importError,
    getValidationErrorsForCsv,
    isGeneric,
    needsDuplicatesCallout,
  } = props;
  const [isImportDisabled, setIsImportDisabled] = useState(
    !fileUrl || isSubmitting,
  );
  const { upload, loading: isUploading } = useFileUploader();
  const [isValidating, setIsValidating] = useState(false);
  const showNewInstructionsComponent = integration.slug === 'coinbase';
  const csvIntegrationCoverageInfo =
    integration?.info?.csvIntegrationCoverageInfo;

  return (
    <>
      <FormContent>
        <Flex direction="row" gap="4" pt="4">
          <Flex direction="column" gap="2" width="50%">
            <form
              encType="multipart/form-data"
              action={formAction}
              onSubmit={(e) => {
                onSubmit(e);
                setIsImportDisabled(true);
              }}
            >
              <input
                type="hidden"
                id="file_url"
                name="file_url"
                value={fileUrl}
                style={{ display: 'none' }}
              />
              {needsDuplicatesCallout && (
                <DuplicateTransactionsCallout
                  integrationName={integration.name}
                />
              )}
              <TextFormField
                name="walletName"
                description="Wallet name"
                placeholder="Type wallet name"
                defaultValue={integration.name}
                disabled={isSubmitting || isUploading || isValidating}
              />
              <Flex direction="column" gap="2">
                <Text size="2">CSV file</Text>
                <FileInput
                  isImporting={isSubmitting}
                  onChange={onFileChange}
                  fileFormats={integration?.info?.supportedFileFormats}
                  setIsImportDisabled={setIsImportDisabled}
                  upload={upload}
                  isUploading={isUploading}
                  isValidating={isValidating}
                  setIsValidating={setIsValidating}
                  getValidationErrorsForCsv={getValidationErrorsForCsv}
                  isGeneric={isGeneric}
                  integrationSlug={integration.slug}
                />
              </Flex>
              {importError &&
                importError.split('\n').map((error, i) => (
                  <Callout.Root size="1" key={`callout-error-${i}`} color="red">
                    <Callout.Icon>
                      <Info size="16px" />
                    </Callout.Icon>
                    <Callout.Text>{error}</Callout.Text>
                  </Callout.Root>
                ))}
              <Flex direction="row" gap="2" justify="end" mt="4">
                <Button size="3" variant="outline" onClick={onCloseModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="3"
                  variant="soft"
                  disabled={isImportDisabled}
                >
                  {getButtonText(isSubmitting, isUploading, isValidating)}
                </Button>
              </Flex>
            </form>
            {!isGeneric && csvIntegrationCoverageInfo && (
              <CoverageInfoContainer
                dangerouslySetInnerHTML={{ __html: csvIntegrationCoverageInfo }}
              />
            )}
          </Flex>
          <Flex direction="column" width="50%">
            {isGeneric ? (
              <GenericInstructions integration={integration} />
            ) : (
              <>
                {showNewInstructionsComponent ? (
                  <GenericInstructions integration={integration} />
                ) : (
                  <InstructionContainer>
                    <Instructions
                      integration={integration}
                      showCSVInstructions
                    />
                  </InstructionContainer>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </FormContent>
    </>
  );
};
