import { Popover } from '@radix-ui/themes';
import { styled } from 'styled-components';

export const JobStatus = styled.div<{
  $isRunning?: boolean;
  $hasError?: boolean;
}>`
  width: 18px;
  height: 18px;
  background-color: ${({ $isRunning, $hasError }) => {
    if ($isRunning) {
      return 'var(--amber-6)';
    } else if ($hasError) {
      return 'var(--red-6)';
    }
    return 'var(--green-6)';
  }};

  color: ${({ $isRunning, $hasError }) => {
    if ($isRunning) {
      return 'var(--amber-10)';
    } else if ($hasError) {
      return 'var(--red-10)';
    }
    return 'var(--green-10)';
  }};

  padding: 2px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.spin svg {
    animation: rotation 3s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const NotificationContent = styled(Popover.Content)`
  text-align: center;
`;

export const IconWrapper = styled.span`
  line-height: 1;
`;
