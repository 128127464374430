import React, { useEffect, useState } from 'react';
import { getFileExtensions } from 'src/deprecated-components/FileInput/utils';

import {
  FileFormats,
  GetValidationErrorsForCsvQuery,
  GetValidationErrorsForCsvQueryVariables,
} from '@/types/graphql-types';

import { LazyQueryHookOptions, LazyQueryResult } from '@apollo/client';
import {
  Avatar,
  Callout,
  Flex,
  Heading,
  IconButton,
  Link,
  Progress,
  Text,
} from '@radix-ui/themes';
import { FileSpreadsheet, Info, Trash2 } from 'lucide-react';
import { FileBoxContainer, StyledFileInput } from './styles';
import { uploadStatusString } from './utils';

export interface FileInputProps {
  isImporting?: boolean;
  isGeneric?: boolean;
  onChange?: (filepath?: string) => void;
  fileFormats?: FileFormats[];
  setIsImportDisabled?: (isDisabled: boolean) => void;
  upload: (file: File, filename: string) => Promise<string>;
  isUploading: boolean;
  isValidating: boolean;
  setIsValidating: (isValidating: boolean) => void;
  getValidationErrorsForCsv?: (
    options?: LazyQueryHookOptions<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >,
  ) => Promise<
    LazyQueryResult<
      GetValidationErrorsForCsvQuery,
      GetValidationErrorsForCsvQueryVariables
    >
  >;
  integrationSlug?: string;
}

export const FileInput = (props: FileInputProps) => {
  const {
    isImporting,
    isGeneric,
    onChange,
    setIsImportDisabled,
    upload,
    isUploading,
    isValidating,
    setIsValidating,
    getValidationErrorsForCsv,
    integrationSlug,
  } = props;
  const [droppedFiles, setDroppedFiles] = useState(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [validationError, setValidationError] = useState<string>('');
  const [uploadError, setUploadError] = useState<string>('');

  const [progressBarValue, setProgressBarValue] = useState(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isImporting) {
      // increase the progress bar value every 2 seconds when importing
      intervalId = setInterval(() => {
        setProgressBarValue((prevProgress) => {
          const newProgress = prevProgress + 10;
          return newProgress <= 100 ? newProgress : 100;
        });
      }, 2000);
    }

    // cleanup the interval when isImporting becomes false
    return () => {
      clearInterval(intervalId);
    };
  }, [isImporting]);

  const limitEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnterOrOver = (event: React.DragEvent<HTMLDivElement>) => {
    limitEvent(event);
    setIsDraggedOver(true);
  };

  const onDragLeaveOrEnd = (event: React.DragEvent<HTMLDivElement>) => {
    limitEvent(event);
    setIsDraggedOver(false);
  };

  const onDropRestrictedExtensions = (
    event: React.DragEvent<HTMLDivElement>,
    allowedExtensions: string,
  ) => {
    limitEvent(event);
    const files = event.dataTransfer.files;
    const allowedExtensionsArray = allowedExtensions
      .split(',')
      .map((ext) => ext.trim().toLowerCase());
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedExtensionsArray.includes(fileExtension)) {
        submitUpload(files);
      } else {
        const formattedExtensions = allowedExtensionsArray
          .map((ext) => ext.replace(/^\./, ' '))
          .join(',')
          .slice(0, -1);
        setUploadError(
          `Only ${formattedExtensions} files are allowed for this CSV import.`,
        );
      }
    }
  };

  const submitUpload = async (files: FileList) => {
    setIsDraggedOver(false);
    setDroppedFiles(files);
    setUploadError('');
    setValidationError('');
    setIsImportDisabled(true);

    const file = files[0];
    try {
      setProgressBarValue(2);
      const fileUrl = await upload(file, file.name);
      if (!fileUrl) {
        setUploadError('There was an issue while trying to upload the file');
        return;
      }
      setFileUrl(fileUrl);
      onChange(fileUrl);
      setIsValidating(true);
      setProgressBarValue(50);

      const validationResponse = await getValidationErrorsForCsv({
        variables: {
          csvFileUrl: fileUrl,
          isGeneric: isGeneric,
          accountTypeSlug: integrationSlug,
        },
      });
      if (validationResponse?.data) {
        if (validationResponse?.data?.csvValidationErrors?.length > 0) {
          setValidationError(
            validationResponse.data.csvValidationErrors.join('\n'),
          );
        } else {
          setValidationError('');
          setIsImportDisabled(false);
        }
        setIsValidating(false);
      }
      if (validationResponse?.error) {
        setIsValidating(false);
        setValidationError(
          "There was an issue while trying to validate the file's format",
        );
      }
    } catch (e) {
      setUploadError(
        'There was an issue while trying to upload and validate the file',
      );
    }
    setIsValidating(false);
    setProgressBarValue(0);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files.length > 0) {
      submitUpload(files);
    }
  };

  const onRemoveFile = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setDroppedFiles(null);
    setFileUrl('');
    setUploadError('');
    setValidationError('');
    onChange('');
    setProgressBarValue(0);
  };

  const isLoading = isImporting || isUploading || isValidating;
  let state = 'default';
  const isValidationComplete =
    fileUrl && !isUploading && !isValidating && !isImporting;
  if (isValidationComplete) {
    state = !validationError && !uploadError ? 'success' : 'error';
  }

  const uploadStatusText = uploadStatusString(
    isUploading,
    isValidating,
    isImporting,
    validationError,
    isValidationComplete,
  );

  return (
    <>
      <input
        type="hidden"
        id="file_url"
        name="file_url"
        value={fileUrl}
        style={{ display: 'none' }}
      />
      <FileBoxContainer $state={state}>
        {(isLoading || fileUrl) && (
          <Flex direction="row" align="center" gap="3" p="6">
            <Avatar
              fallback={<FileSpreadsheet size="16px" color="var(--gray-12)" />}
              radius="full"
              size="3"
            />
            <Flex direction="column" gap="3" width="100%" justify="center">
              <Heading size="3">
                {droppedFiles ? droppedFiles[0].name : 'transactions.csv'}
              </Heading>
              {isLoading && <Progress value={progressBarValue} size="3" />}
              {(validationError || uploadStatusText) && (
                <Text size="2" color={state === 'error' ? 'red' : undefined}>
                  {validationError ? (
                    <>
                      Validation errors. Fix your file and{' '}
                      <Link href="#" onClick={onRemoveFile}>
                        upload it again.
                      </Link>
                    </>
                  ) : (
                    uploadStatusText
                  )}
                </Text>
              )}
            </Flex>
            {fileUrl && !isLoading && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <IconButton variant="ghost" color="red" onClick={onRemoveFile}>
                <Trash2 size="16px" />
              </IconButton>
            )}
          </Flex>
        )}
        {!isLoading && !fileUrl && (
          <Flex
            direction="column"
            gap="1"
            p="3"
            align="center"
            justify="center"
            style={{
              borderRadius: '50%',
              background: isDraggedOver
                ? 'var(--gray-9)'
                : 'var(--color-panel-solid)',
            }}
            id="file-input-box"
            onDrag={limitEvent}
            onDragStart={limitEvent}
            onDragEnter={onDragEnterOrOver}
            onDragOver={onDragEnterOrOver}
            onDragEnd={onDragLeaveOrEnd}
            onDragLeave={onDragLeaveOrEnd}
            onDrop={(event) =>
              onDropRestrictedExtensions(
                event,
                getFileExtensions(props.fileFormats),
              )
            }
          >
            <Avatar fallback={<FileSpreadsheet size="18px" />} radius="full" />
            <Flex direction="row" align="center">
              <StyledFileInput
                type="file"
                name="file-input"
                id="file-input"
                onChange={onInputChange}
                accept={getFileExtensions(props.fileFormats)}
              />
              <label htmlFor="file-input">
                <Heading size="3">Select</Heading>
              </label>
              <Text size="3">
                <span>&nbsp;</span>a CSV file to upload
              </Text>
            </Flex>
            <Text size="2" color="gray">
              or drag and drop it here
            </Text>
          </Flex>
        )}
      </FileBoxContainer>
      {uploadError && (
        <Callout.Root size="1" color="red">
          <Callout.Icon>
            <Info size="16px" />
          </Callout.Icon>
          <Callout.Text>{uploadError}</Callout.Text>
        </Callout.Root>
      )}
      {validationError &&
        validationError.split('\n').map((error, i) => (
          <Callout.Root size="1" key={`callout-error-${i}`} color="red">
            <Callout.Icon>
              <Info size="16px" />
            </Callout.Icon>
            <Callout.Text>{error}</Callout.Text>
          </Callout.Root>
        ))}
    </>
  );
};
