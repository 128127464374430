import CheckSimple from '@/assets/checkSimple.svg?react';
import CircleNotch from '@/assets/circleNotch.svg?react';
import SignExclamation from '@/assets/sign-exclamation.svg?react';
import { IconWrapper, JobStatus } from './styles';

export const JobStatusIcon = ({
  isRunning,
  hasError,
  isIdle,
}: {
  isRunning: boolean;
  hasError: boolean;
  isIdle: boolean;
}) => {
  return (
    <JobStatus
      $isRunning={isRunning}
      $hasError={hasError}
      className={isRunning ? 'spin' : ''}
    >
      {isRunning && <CircleNotch data-testid="in-progress-job-status" />}
      {hasError && (
        <IconWrapper data-testid="failed-job-status">
          <SignExclamation color="var(--red-9)" />
        </IconWrapper>
      )}
      {isIdle && <CheckSimple data-testid="idle-job-status" />}
    </JobStatus>
  );
};
