import {
  BookOpenText,
  Home,
  Layers,
  Settings,
  Table,
  Wallet,
  WandSparkles,
} from 'lucide-react';
import * as React from 'react';

import LogoIcon from '@/assets/ct-logo-icon.svg?react';
import Logo from '@/assets/enterprise-logo-white.svg?react';
import { NavMain } from '@/components/NavMain';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  SidebarTrigger,
  useSidebar,
} from '@/components/ui/sidebar';
import { Flex } from '@radix-ui/themes';
import { useStatsigClient } from '@statsig/react-bindings';
import { useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { GlobalJobStatus } from './GlobalJobStatus';
import { ProfileDropdown } from './ProfileDropdown';

export function AppSidebar({
  isAuthenticated,
  ...props
}: React.ComponentProps<typeof Sidebar> & { isAuthenticated?: boolean }) {
  const { client } = useStatsigClient();
  const shouldShowRules = client?.checkGate('enterprise_rules');
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const { state } = useSidebar();
  const isCollapsed = state === 'collapsed';

  const navItems = [
    { title: 'Home', slug: '/home', icon: Home, isActivePattern: '/home' },
    {
      title: 'Accounting',
      slug: '/accounting',
      icon: BookOpenText,
      isActivePattern: '/accounting/*',
    },
    {
      title: 'Wallets',
      slug: '/wallets',
      icon: Wallet,
      isActivePattern: '/wallets/*',
    },
    {
      title: 'Transactions',
      slug: '/transactions',
      icon: Table,
      isActivePattern: '/transactions/*',
      items: [
        {
          title: 'Open',
          slug: '/transactions/period/open',
          isActivePattern: '/transactions/period/open',
        },
        {
          title: 'Closed',
          slug: '/transactions/period/closed',
          isActivePattern: '/transactions/period/closed',
        },
      ],
    },
    ...(shouldShowRules
      ? [
          {
            title: 'Rules',
            slug: '/rules',
            icon: WandSparkles,
            isActivePattern: '/rules/*',
          },
        ]
      : []),
    {
      title: 'Reports',
      slug: '/reports',
      icon: Layers,
      isActivePattern: '/reports/*',
    },
  ];
  return (
    <Sidebar
      collapsible="icon"
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              className="hover:bg-transparent active:bg-transparent"
            >
              <Flex direction="row" justify="between" align="center">
                <Flex direction="row" justify="between" align="center">
                  {isCollapsed ? (
                    isHovered ? (
                      <SidebarTrigger style={{ marginLeft: '-5px' }} />
                    ) : (
                      <LogoIcon width="18" height="18" />
                    )
                  ) : (
                    <Logo width="200" height="18" />
                  )}
                </Flex>
                <SidebarTrigger
                  style={{
                    paddingRight: '3px',
                    paddingLeft: '3px',
                    paddingTop: '1px',
                    paddingBottom: '1px',
                  }}
                />
              </Flex>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            {isAuthenticated && <GlobalJobStatus />}
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={navItems} />
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              tooltip={'Settings'}
              asChild
              isActive={Boolean(matchPath('/settings/*', location.pathname))}
            >
              <NavLink to="/settings">
                <Settings />
                <span>Settings</span>
              </NavLink>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {isAuthenticated && <ProfileDropdown />}
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
