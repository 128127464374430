import { logout } from '@/app/auth';
import { SidebarMenuButton, SidebarMenuItem } from '@/components/ui/sidebar';
import { DropdownMenu, Text } from '@radix-ui/themes';
import { Building2, ChevronsUpDown } from 'lucide-react';
import { styled } from 'styled-components';
import { useGetAppInfo } from '../app/root/appInfoContext';

const StyledEmailText = styled(Text)`
  color: var(--gray-12);
  cursor: text;
`;

export const ProfileDropdown = () => {
  const { appInfo } = useGetAppInfo();
  const { orgName, email } = appInfo ?? {};
  return (
    <SidebarMenuItem>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          >
            <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
              <Building2 className="size-4" />
            </div>
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">{orgName}</span>
            </div>
            <ChevronsUpDown className="ml-auto" />
          </SidebarMenuButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Label>
            <StyledEmailText size="2">{email}</StyledEmailText>
          </DropdownMenu.Label>
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            color="red"
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
          >
            Log out
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </SidebarMenuItem>
  );
};
