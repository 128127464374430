const baseImagesURL = 'https://cdn.cointracker.io/static/images';
export const aboutUsImagesURL = baseImagesURL + '/about_us';
export const cpaImagesURL = baseImagesURL + '/cpa';
export const cryptosImagesURL = baseImagesURL + '/cryptos';
export const exchangeImagesURL = baseImagesURL + '/exchanges';
export const iconsImagesURL = baseImagesURL + '/icons';
export const landingImagesURL = baseImagesURL + '/landing';
export const screenshotsImagesURL = baseImagesURL + '/screenshots';
export const spritesImagesURL = baseImagesURL + '/sprites';
export const thirdPartyImagesURL = baseImagesURL + '/third_party';
export const fiatlImagesUrl = baseImagesURL + '/fiats';
export const connectImagesUrl = baseImagesURL + '/connect';
export const walletsImagesUrl = baseImagesURL + '/wallets';

export const cryptoIconsCmcURL64 =
  'https://cdn.cointracker.io/crypto-icons/cmc/64x64';
export const LOGO_TOP_URL = '/';
