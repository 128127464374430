import {
  EnterpriseJob,
  JobResult,
  JobStatus,
  OrganizationPeriod,
  PeriodStatus,
} from '@/types/graphql-types';

export const isJobCompleted = (job?: JobResult) => {
  if (!job) return false;

  return job.status === JobStatus.Finished;
};

export const isJobFailed = (job?: JobResult) => {
  if (!job) return false;

  const jobReturnValue = JSON.parse(job.returnValue);

  return (
    job.status === JobStatus.Failed ||
    (job.status === JobStatus.Finished && jobReturnValue?.success === false)
  );
};

export const isJobRunning = (job?: JobResult) => {
  if (!job) return false;

  return (
    job.status === JobStatus.Queued ||
    job.status === JobStatus.Scheduled ||
    job.status === JobStatus.Started
  );
};

export const isJobForCurrentPeriod = (
  job?: JobResult,
  period?: OrganizationPeriod,
) => {
  if (!job) return false;

  if (job.type === EnterpriseJob.ChangePeriodStatus) {
    return job?.args && JSON.parse(job.args)?.period?.id === period?.id;
  }

  if (job.type === EnterpriseJob.SyncJournalEntries) {
    return job?.args && JSON.parse(job.args)?.end_date === period?.endDate;
  }

  return false;
};

export const isJobLockingPeriod = (job?: JobResult) => {
  if (!job) return false;

  if (job.type === EnterpriseJob.ChangePeriodStatus) {
    return (
      job?.args && JSON.parse(job.args)?.period?.status === PeriodStatus.Open
    );
  }
};

export const isJobClosingPeriod = (job?: JobResult) => {
  if (!job) return false;

  if (job.type === EnterpriseJob.ChangePeriodStatus) {
    return (
      job?.args && JSON.parse(job.args)?.period?.status === PeriodStatus.Closing
    );
  }
};
