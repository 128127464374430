import {
  OrganizationPeriod,
  PeriodStatus,
  useGetPeriodsQuery,
} from '@/types/graphql-types';

export const useGetPeriods = () => {
  const res = useGetPeriodsQuery();
  const periods = res.data?.periods?.page?.objects;
  return { ...res, periods };
};

export const getPeriodReviewChecks = (period: OrganizationPeriod) => {
  const info = period.info ? JSON.parse(period.info) : {};
  // If the period is closed, we don't need to check for reviewed status
  const reviewChecks =
    period.status === PeriodStatus.Open
      ? {
          journalEntries: info.journal_entries ?? false,
          transactions: info.transactions ?? false,
          balanceDifferences: info.balance_differences ?? false,
          incomeStatement: info.income_statement ?? false,
          holdings: info.holdings ?? false,
        }
      : {
          journalEntries: true,
          transactions: true,
          balanceDifferences: true,
          incomeStatement: true,
          holdings: true,
        };
  return new Map<string, boolean>(Object.entries(reviewChecks));
};

export const isPeriodReviewed = (period: OrganizationPeriod) => {
  const reviewChecks = getPeriodReviewChecks(period);
  return Array.from(reviewChecks.values()).every((value) => value);
};

export const getPeriodReviewProgress = (period: OrganizationPeriod) => {
  const reviewChecks = getPeriodReviewChecks(period);
  const reviewedCount = Array.from(reviewChecks.values()).filter(
    (reviewed) => reviewed,
  ).length;
  return Math.round((reviewedCount / reviewChecks.size) * 100);
};
