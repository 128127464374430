import { AppSidebar } from '@/components/AppSideBar';
import { SidebarProvider } from '@/components/ui/sidebar';
import { Box, ScrollArea } from '@radix-ui/themes';

interface Props {
  children: React.ReactNode;
  isAuthenticated?: boolean;
}
export function SideBarWithTopBarLayout({ isAuthenticated, children }: Props) {
  return (
    <Box height="100vh">
      <SidebarProvider>
        <AppSidebar isAuthenticated={isAuthenticated} />
        <ScrollArea style={{ height: '100%' }} scrollbars="both">
          {children}
        </ScrollArea>
      </SidebarProvider>
    </Box>
  );
}

export default SideBarWithTopBarLayout;
