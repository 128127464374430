import { Route, createRoutesFromElements } from 'react-router-dom';
import { DashboardAppLayout } from '../../layouts/DashboardAppLayout';
import { PageNotFound } from '../../pages/404';
import { ErrorPage, GlobalErrorPage } from '../root/ErrorPage';
import { sentryCreateBrowserRouter } from '../root/Sentry';
import { ShellWithAuthProvider } from '../root/Shell';
import { NavigateWithParams } from './NavigateWithParams';
import { Page, PublicPage } from './Page';

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<ShellWithAuthProvider />}
      errorElement={<GlobalErrorPage />}
    >
      <Route
        path="/auth0/login"
        element={<PublicPage page={() => import('../../pages/auth0/login')} />}
      />
      <Route
        path="/error/no-org"
        element={
          <PublicPage page={() => import('../../pages/error/no-org/page')} />
        }
      />
      <Route
        path="/error/auth"
        element={
          <PublicPage
            page={() => import('../../pages/error/auth-error/page')}
          />
        }
      />
      <Route path="*" element={<PageNotFound />} />
      <Route element={<DashboardAppLayout />} errorElement={<ErrorPage />}>
        <Route
          index
          element={<NavigateWithParams replace to="/accounting" />}
        />
        <Route
          path="/home"
          element={<Page page={() => import('../../pages/home/page')} />}
        />
        <Route
          path="/dashboard"
          element={<Page page={() => import('../../pages/coming-soon')} />}
        />
        <Route
          path="/wallets"
          element={<Page page={() => import('../../pages/wallets')} />}
        >
          <Route
            path=":walletType?/:walletId?"
            element={
              <Page
                page={() => import('../../pages/wallets/WalletsContainer')}
              />
            }
          />
        </Route>
        <Route
          path="/authorized/coinbase"
          element={
            <Page page={() => import('../../pages/authorized/coinbase/page')} />
          }
        />
        <Route
          path="/authorized/uphold"
          element={
            <Page page={() => import('../../pages/authorized/uphold/page')} />
          }
        />
        <Route
          path="/authorized/coinjar"
          element={
            <Page page={() => import('../../pages/authorized/coinjar/page')} />
          }
        />

        <Route
          path="/connections"
          element={<Page page={() => import('../../pages/404')} />}
        />
        <Route
          path="/transactions"
          element={
            <NavigateWithParams replace to="/transactions/period/open" />
          }
        />
        <Route
          path="/transactions/period/:periodId"
          element={<Page page={() => import('../../pages/transactions')} />}
        />
        <Route
          path="/transactions/add"
          element={<Page page={() => import('../../pages/TransactionForm')} />}
        />
        <Route
          path="/transactions/:transactionId"
          element={<Page page={() => import('../../pages/TransactionForm')} />}
        />
        <Route
          path="/rules"
          element={<Page page={() => import('../../pages/rules/page')} />}
        >
          <Route
            index
            element={<Page page={() => import('../../pages/rules/active')} />}
          />

          <Route
            path="active"
            element={<Page page={() => import('../../pages/rules/active')} />}
          />
          <Route
            path="archived"
            element={<Page page={() => import('../../pages/rules/archived')} />}
          />
          <Route
            path="templates"
            element={
              <Page page={() => import('../../pages/rules/templates')} />
            }
          />
          <Route
            path=":ruleId"
            element={<Page page={() => import('../../pages/rules/active')} />}
          />
        </Route>
        <Route
          path="/rules-debug"
          element={
            <Page
              page={() => import('../../pages/rules/debug/RulesDebugger')}
            />
          }
        />
        <Route
          path="/accounting"
          element={<Page page={() => import('../../pages/accounting')} />}
        />
        <Route
          path="/accounting/:periodId"
          element={<Page page={() => import('../../pages/accounting')} />}
        />
        <Route
          path="/accounting/:periodId/report"
          element={<Page page={() => import('../../pages/accounting')} />}
        />
        <Route
          path="/journal-entries"
          element={<Page page={() => import('../../pages/journal-entries')} />}
        />
        <Route
          path="/journal-entries/:periodId"
          element={<Page page={() => import('../../pages/journal-entries')} />}
        />

        <Route
          path="/reports"
          element={<Page page={() => import('../../pages/reports')} />}
        />

        <Route
          path="/settings"
          element={<Page page={() => import('../../pages/settings')} />}
        >
          <Route
            index
            element={
              <Page page={() => import('../../pages/settings/organization')} />
            }
          />
          <Route
            path="organization"
            element={
              <Page page={() => import('../../pages/settings/organization')} />
            }
          />
          <Route
            path="rollups"
            element={
              <Page page={() => import('../../pages/settings/rollups')} />
            }
          />
          <Route
            path="users"
            element={<Page page={() => import('../../pages/settings/users')} />}
          />
          <Route
            path="chart-of-accounts"
            element={
              <Page
                page={() => import('../../pages/settings/chart-of-accounts')}
              />
            }
          />
          <Route
            path="notifications"
            element={<Page page={() => import('../../pages/coming-soon')} />}
          />
          <Route
            path="accounting-integration"
            element={
              <Page
                page={() =>
                  import('../../pages/settings/accounting-integration')
                }
              />
            }
          />
          <Route
            path="accounting-integration/callback"
            element={
              <Page
                page={() =>
                  import('../../pages/settings/accounting-integration')
                }
              />
            }
          />
        </Route>
        <Route
          path="/user/profile"
          element={<Page page={() => import('../../pages/profile')} />}
        >
          <Route
            path=":page"
            element={<Page page={() => import('../../pages/profile')} />}
          />
        </Route>
        <Route
          path="/auth0/callback"
          element={
            <PublicPage page={() => import('../../pages/auth0/callback')} />
          }
        />
        <Route
          path="/closing/:periodId/review"
          element={
            <Page page={() => import('../../pages/closing/review/page')} />
          }
        />
        <Route
          path="/closing/:periodId/report"
          element={
            <Page page={() => import('../../pages/closing/report/page')} />
          }
        />
      </Route>
    </Route>,
  ),
);
