import * as Sentry from '@sentry/browser';
import { useContext } from 'react';
import { AuthenticatedUser, UserContext } from 'src/app/user';
import { cryptoIconsCmcURL64, fiatlImagesUrl } from 'src/common/constants';
import { formatWalletLabel } from 'src/common/wallet-address';
import {
  isCustomWallet,
  isExchange,
} from 'src/pages/wallets/WalletsContainer/utils';
import {
  Currency,
  CurrencyType,
  CustomWallet,
  Exchange,
  OtherTransactions,
  TransactionWalletInfo,
  Wallet,
  WalletTypeEnum,
  useGetWalletsQuery,
} from 'src/types/graphql-types';
import DocumentLinesCircleUrl from '../../../../deprecated-components/icons/documentLinesCircle.svg?url';
import WalletCircleUrl from '../../../../deprecated-components/icons/walletCircle.svg?url';

export type SubWallet = {
  id: string;
  name: string;
  exchangeName?: string;
  currency?: Currency;
  icon?: string;
  address?: string;
  uaid?: string;
};

export type UserWallet = {
  id: string;
  name: string;
  exchangeName?: string;
  currency?: Currency;
  icon?: string;
  address?: string;
  type?: WalletTypeEnum;
  isOtherTransactionWallet?: boolean;
  wallets?: SubWallet[];
};

export interface UseGetWalletParams {
  allowOtherTransactions?: boolean;
  flattenResults?: boolean;
}

export default function useGetWallets({
  allowOtherTransactions = true,
  flattenResults = false,
}: UseGetWalletParams = {}) {
  const { loading, data } = useGetWalletsQuery();
  const wallets: UserWallet[] = [];
  const { exchanges = [], localWallets = [], customWallets = [] } = data || {};
  const user = useContext(UserContext) as AuthenticatedUser;

  if (loading) {
    return { loading, wallets };
  }

  try {
    if (flattenResults) {
      exchanges.forEach((exchange) => {
        exchange.wallets?.forEach((wallet) => {
          wallets.push({
            id: wallet.id,
            // address: exchange.,
            exchangeName: exchange.name,
            name: wallet.name,
            currency: wallet.currency,
            icon: getIconFromCurrency(wallet?.currency),
            type: WalletTypeEnum.Wallet,
          });
        });
      });
    } else {
      exchanges.forEach((exchange) => {
        const subWallets = exchange.wallets?.map((wallet) => {
          return {
            id: wallet.id,
            exchangeName: exchange.name,
            name: wallet.name,
            currency: wallet.currency,
            icon: getIconFromCurrency(wallet?.currency),
            type: WalletTypeEnum.Wallet,
            uaid: wallet.uaid,
          };
        });
        wallets.push({
          id: exchange.id,
          name: exchange.name,
          type: WalletTypeEnum.Wallet,
          wallets: subWallets,
          icon: exchange.imageUrl,
        });
      });
    }
    localWallets.forEach((wallet) => {
      wallets.push({
        id: wallet.id,
        address: wallet.address,
        name: getWalletName(wallet),
        currency: wallet.currency,
        icon: getIconFromCurrency(wallet?.currency),
        type: WalletTypeEnum.Wallet,
      });
    });
    customWallets.forEach((wallet) => {
      wallets.push({
        id: wallet.id,
        name: wallet.name,
        type: WalletTypeEnum.CustomWallet,
        icon: DocumentLinesCircleUrl,
      });
    });

    // some transactions come while including a wallet but with walletId: null. This is the "Other transactions" wallet.
    // So we are providing a "fake" UI only wallet
    if (allowOtherTransactions) {
      wallets.push(OtherTransactionsWallet);
    }
  } catch (e) {
    Sentry.captureException(new Error('[PPP-Debug]: .forEach loop failed'), {
      extra: {
        loading,
        user: {
          publicId: user.publicId ?? user.anonymousId,
          isFetching: user.isFetching,
          hasFetched: user.hasFetched,
          isAuthenticated: user.isAuthenticated,
        },
        walletQuery: {
          data: JSON.stringify(data),
        },
      },
    });
    return { loading: true, wallets: [] };
  }
  return { loading, wallets };
}

export function getIconFromCurrency(currency: Currency) {
  if (!currency || !currency.type || !currency.symbol) {
    return;
  }
  let icon;
  if (currency?.type === CurrencyType.Fiat) {
    icon = `${fiatlImagesUrl}/${currency.symbol.toLowerCase()}.png`;
  } else if (currency?.type === CurrencyType.Crypto && currency.cmcId) {
    icon = `${cryptoIconsCmcURL64}/${currency.cmcId}.png`;
  }
  return icon;
}

export function getUserWalletFromTransactionWallet(
  wallet?: TransactionWalletInfo,
): UserWallet {
  if (!wallet) {
    return null;
  }
  // the backend will return a null walletId for "other transactions"
  if (!wallet.walletId) {
    return OtherTransactionsWallet;
  }
  return {
    id: wallet.walletId,
    name: wallet.label,
    address: wallet.address,
    currency: wallet.currency,
    type: wallet.walletType,
    icon: getIconFromCurrency(wallet?.currency),
  };
}

export const OtherTransactionsWallet: UserWallet = {
  id: 'other-transactions',
  name: 'Other transactions',
  isOtherTransactionWallet: true,
  icon: WalletCircleUrl,
};

export function isWallet(
  wallet: Wallet | CustomWallet | Exchange | OtherTransactions,
): wallet is Wallet {
  if ((wallet as Wallet)?.currency) {
    return true;
  }
  return false;
}

export function getWalletName(wallet: Wallet | CustomWallet | Exchange) {
  if (!wallet) {
    return null;
  }
  if (isExchange(wallet) || isCustomWallet(wallet)) {
    return wallet.name;
  }

  return (
    wallet.name ||
    `${wallet.currency?.symbol} ${formatWalletLabel(wallet?.address || 'Wallet')}`
  );
}
