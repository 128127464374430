import { ChevronRight, type LucideIcon } from 'lucide-react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { useState } from 'react';

type NavItem = {
  title: string;
  slug: string;
  icon?: LucideIcon;
  isActivePattern?: string;
  items?: {
    title: string;
    slug: string;
    isActivePattern?: string;
  }[];
};

type NavItems = NavItem[];

const NavItem = ({ item }: { item: NavItem }) => {
  const location = useLocation();
  const isActive = Boolean(matchPath(item.isActivePattern, location.pathname));
  const [isOpen, setIsOpen] = useState(isActive);
  if (!item.items) {
    return (
      <SidebarMenuItem key={item.title}>
        <SidebarMenuButton tooltip={item.title} asChild isActive={isActive}>
          <NavLink to={item.slug}>
            <item.icon />
            <span>{item.title}</span>
          </NavLink>
        </SidebarMenuButton>
      </SidebarMenuItem>
    );
  }
  return (
    <Collapsible
      key={item.title}
      open={isOpen}
      asChild
      className="group/collapsible"
    >
      <SidebarMenuItem>
        <SidebarMenuButton tooltip={item.title} isActive={isActive} asChild>
          <NavLink to={item.slug}>
            {item.icon && <item.icon />}
            <span>{item.title}</span>
            <ChevronRight
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
              className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"
            />
          </NavLink>
        </SidebarMenuButton>

        <CollapsibleContent>
          <SidebarMenuSub>
            {item.items?.map((subItem) => (
              <SidebarMenuSubItem key={subItem.title}>
                <SidebarMenuSubButton
                  asChild
                  isActive={Boolean(
                    matchPath(subItem.isActivePattern, location.pathname),
                  )}
                >
                  <NavLink to={subItem.slug}>
                    <span>{subItem.title}</span>
                  </NavLink>
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};

export function NavMain({ items }: { items: NavItems }) {
  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
